import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Fade from 'react-reveal/Fade'
import uuidv4 from 'uuid/v4'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { styles } from './styles'


class Homepage extends Component {
  componentDidUpdate(prevProps) {
    const { lang } = this.props

    if (lang !== prevProps.lang) {
      this.getVideo()
    }
  }

  getVideo = () => {
    const { lang, t } = this.props

    let videoUrl = 'https://www.youtube.com/embed/Y7p4M0zAGvs'

    if (lang === 'it') {
      videoUrl = 'https://www.youtube.com/embed/Dh62_c0m4M8'
    }

    return (
      <Fade delay={300}>
        <div style={styles.videoContainer}>
          <iframe style={styles.iframe} src={videoUrl} title={t('title')} frameBorder="0" allowFullScreen />
        </div>
      </Fade>
    )
  }

  getText = () => {
    const { t } = this.props
    const paragraph = []

    for (let i = 1; i < 11; i++) {
      paragraph.push(
        <Fade delay={300} key={`paragraph${uuidv4()}`}>
          <p style={styles.paragraph}>{t(`bio${i}`)}</p>
        </Fade>
      )
    }

    return paragraph
  }

  render() {
    const { t } = this.props
    const bio = this.getText()
    const video = this.getVideo()

    return (
      <div style={styles.container} data-cy="services">
        <div style={styles.internalContainer}>
          <Fade delay={300}>
            <p style={styles.title}>{t('biography')}</p>
          </Fade>
          {video}
          {bio}
        </div>
      </div>
    )
  }
}

Homepage.propTypes = {
  lang: PropTypes.string,
  t: PropTypes.shape({}).isRequired
}

Homepage.defaultProps = {
  lang: 'en'
}

const mapStateToProps = state => ({
  lang: state.language.active
})

export default withTranslation()(connect(
  mapStateToProps
)(Homepage))
