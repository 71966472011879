import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Icon, Button, Checkbox } from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom'
import { LINK_COOKIE } from 'controller/constants'
import {
  COOKIE_MESSAGE, ACCEPT, IGNORE, COOKIE_POLICY,
  NECESSARY_COOKIES, PERFORMANCE_COOKIES
} from 'common/legal/constants'
import { styles } from '../styles'

const CookieBar = ({
  cookieStatus, approveCookie, rejectCookie, toggleCookie, showCookieBar
}) => {
  const { visible, uiApproved } = cookieStatus
  return (
    <Fragment>
      <div style={styles.triangle} />
      <Icon name="certificate" onClick={showCookieBar} style={styles.icon} />
      {visible && (
      <div style={styles.containerCookieBar}>
        <div style={styles.internalContainer}>
          <div style={styles.message}>
            {COOKIE_MESSAGE}
          </div>
          <div style={styles.checkboxes}>
            <Checkbox toggle checked label={NECESSARY_COOKIES} style={styles.label} />
            <Checkbox toggle label={PERFORMANCE_COOKIES} onChange={toggleCookie} checked={uiApproved} />
          </div>
          <div style={styles.buttons}>
            <Link to={LINK_COOKIE} style={styles.button}>
              {COOKIE_POLICY}
            </Link>

            <div style={styles.buttonsContainer}>
              <Button onClick={rejectCookie} style={styles.button}>
                {IGNORE}
              </Button>
              <Button onClick={approveCookie} style={styles.button}>
                {ACCEPT}
              </Button>
            </div>
          </div>
        </div>
      </div>
      )
    }
    </Fragment>
  )
}

CookieBar.propTypes = {
  cookieStatus: PropTypes.shape({
    visible: PropTypes.bool,
    approved: PropTypes.bool,
    uiApproved: PropTypes.bool,
  }),
  approveCookie: PropTypes.func.isRequired,
  rejectCookie: PropTypes.func.isRequired,
  toggleCookie: PropTypes.func.isRequired,
  showCookieBar: PropTypes.func.isRequired,
}

CookieBar.defaultProps = {
  cookieStatus: {
    visible: true,
    approved: false,
    uiApproved: true
  }
}


export default withRouter(CookieBar)
