import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Image, Flag, Button } from 'semantic-ui-react'
import { LINK_HOMEPAGE } from 'controller/constants'
import Logo from 'assets/img/logo-64.png'
import { styles } from './styles'

const Header = ({ switchToEn, switchToIt, currentLanguage }) => (
  <header style={styles.header} data-cy="header">
    <div style={styles.headerContainer}>
      <div style={styles.logo}>
        <Link to={LINK_HOMEPAGE}><Image src={Logo} size="mini" /></Link>
      </div>

      <div style={styles.navbar}>
        <Button
          onClick={switchToEn}
          style={
              currentLanguage === 'en'
                ? styles.flagButtonActive
                : styles.flagButton
            }
        >
          <Flag name="gb" style={styles.flag} />
        </Button>
        <Button
          onClick={switchToIt}
          style={
              currentLanguage === 'it'
                ? styles.flagButtonActive
                : styles.flagButton
            }
        >
          <Flag name="it" style={styles.flag} />
        </Button>
      </div>
    </div>
  </header>
)


Header.propTypes = {
  currentLanguage: PropTypes.string,
  switchToEn: PropTypes.func.isRequired,
  switchToIt: PropTypes.func.isRequired
}

Header.defaultProps = {
  currentLanguage: 'en'
}

export default withRouter(Header)
