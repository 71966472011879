import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { LINK_PRIVACY, LINK_TERMS, LINK_COOKIE } from 'controller/constants'
import { COPYRIGHT } from '../constants'
import { styles } from './styles'

const Footer = () => (
  <div style={styles.container}>
    {COPYRIGHT}
    <div style={styles.separator}>|</div>
    <Link to={LINK_TERMS} style={styles.text} data-cy="footer-link">
            Terms & Conditions
    </Link>
    <div style={styles.separator}>|</div>
    <Link to={LINK_PRIVACY} style={styles.text} data-cy="footer-link">
                  Privacy Policy
    </Link>
    <div style={styles.separator}>|</div>
    <Link to={LINK_COOKIE} style={styles.text} data-cy="footer-link">
                  Cookie Policy
    </Link>
  </div>
)

export default withRouter(Footer)
