import { UPDATE_LANGUAGE } from '../constants'

const initState = {
  active: 'en'
}

export const language = (state = initState, action) => {
  const { type, payload } = action
  switch (type) {
    case UPDATE_LANGUAGE:
      return {
        ...state,
        active: payload.value
      }
    default: return state
  }
}
