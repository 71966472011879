import backgroundImage from 'assets/img/eberhard-grossgasteiger.jpg'

export const styles = {
  container: {
    paddingTop: 120,
    width: '100%',
    height: '100%',
    background: `linear-gradient(rgba(0,0,0,0.45),rgba(0,0,0,0.45)), url(${backgroundImage})`,
    minHeight: '100vh',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: 'cover'
  }
}
