import React from 'react'
import { useTranslation } from 'react-i18next'
import Fade from 'react-reveal/Fade'
import uuidv4 from 'uuid/v4'
import { styles } from './styles'

const PrevenientAct = () => {
  const { t } = useTranslation()

  const getText = () => {
    const paragraph = []
    for (let i = 1; i < 43; i++) {
      paragraph.push(
        <Fade delay={300} key={`paragraph${uuidv4()}`}>
          <p style={styles.paragraph}>{t(`prevAct${i}`)}</p>
        </Fade>
      )
    }

    return paragraph
  }

  return (
    <div style={styles.container} data-cy="services">
      <div style={styles.internalContainer}>
        <Fade delay={300}>
          <p style={styles.title}>{t('prevAct')}</p>
        </Fade>
        {getText()}
      </div>
    </div>
  )
}

export default PrevenientAct
