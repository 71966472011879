/* eslint max-len:0 */
export const it = {
  title: 'Vivere nel Divin Volere',
  biography: 'Biografia',
  bio1: 'La Serva di Dio Luisa Piccarreta nacque nella citta\' di Corato nella provincia di Bari, Italia, la mattina del 23 aprile 1865, domenica \'in Albis\' (attuale festa della Divina Misericordia), e lo stesso giorno fu battezzata; visse sempre li\' e mori\' in concetto di santita\' il 4 marzo 1947. Nacque dalla signora Rosa Tarantino e dal signor Vito Nicola Piccarreta, lavoratore di una tenuta della famiglia Mastrorilli. La piccola Luisa, la quarta di cinque figlie, era di temperamento timido, pauroso; cio\' nonostante, era anche vivace ed allegra.',
  bio2: 'La domenica \'in Albis\' del 1874, a nove anni, ricevette la Prima Comunione e lo stesso giorno il Sacramento della Cresima. Gia\' da piccola mostrava una forte inclinazione a dedicare lunghi periodi di tempo per la meditazione e preghiera, avendo come elementi fondamentali della sua vita interiore un acceso amore a Gesu\' sofferente nella sua Passione e prigioniero di amore nell\'Eucaristia, ed una matura e solida devozione alla Santissima Vergine Maria. I suoi genitori non prestarono attenzione a queste inclinazioni, fino a che comincio\' a manifestarsi nella loro figlia una misteriosa malattia che la obbligava a rimanere a letto. I medici non trovandone la causa e non potendo darne una diagnosi, suggerirono la visita di un sacerdote. Rimasero attoniti quando al segno della croce Luisa ritorno\' nel suo \'solito stato\', come lei stessa lo avrebbe chiamato dopo anni nei suoi scritti.',
  bio3: 'Attorno ai diciotto anni, mentre lavorava nella sua stanza, mentre faceva la meditazione sulla Passione di Gesu\', senti\' il suo cuore oppresso e che le mancava il respiro, spaventata, usci\' sul balcone e da li\' vide che la strada era piena di persone che spingevano Gesu\' che stava portando la croce. Allora Gesu\', sofferente ed insanguinato, alzo\' gli occhi verso di lei pronunciando queste parole: \'Anima, aiutami!\'.',
  bio4: 'Luisa entro\' nella sua abitazione con il cuore dilaniato dal dolore e, piangendo, gli ha detto: \'Quanto soffri, o mio buon Gesu\'! Potessi io almeno aiutarti e liberarti da quei lupi rabbiosi, o almeno soffrire io le tue pene, i tuoi dolori e le tue fatiche, in vece tua, per darti cosi\' il più grande sollievo! Ah, mio Bene!, fa\' che anche io soffra, perche\' non e\' giusto che tu debba soffrire tanto per amore mio e che io, peccatrice, stia senza soffrire nulla per te\'. E da quel momento ripetendo sempre il suo Fiat, diventarono sempre più frequenti i periodi trascorsi nel letto fino alla completa immobilita\' per 62 anni.',
  bio5: 'In questa \'piccola prigione\' Gesu\' le fece conoscere il gran desiderio del Suo Cuore, che l\'uomo viva nella sua Volonta\', affinche\' ritorni all\'ordine, al posto e allo scopo per cui fu creato da Dio. Questo e\' cio\' che Egli stesso ci insegno\' a chiedere nel Padre Nostro \'Sia fatta la tua Volonta\' come in cielo cosi\' in terra\'. Cosi\' deposito\' in lei le sue meravigliose Verita\', affinche\' a sua volta, come \'araldo\' del Regno, depositaria e segretaria dei tesori della Divina Volonta\', facesse conoscere il decreto eterno della venuta del suo Regno nella Chiesa e nel mondo intero. ',
  bio6: 'A tal proposito scrive Sant\'Annibale Maria Di Francia: ',
  bio7: '\'Nostro Signore che di secolo in secolo accresce sempre più le meraviglie del Suo Amore, pare che di questa vergine, che Egli chiama \'la più piccola che abbia trovata sulla terra\', destituita di ogni istruzione, abbia voluto formare un istrumento adatto per una Missione cosi\' sublime, cui nessun\'altra si potra\' paragonare, cioe\' il trionfo della Divina Volonta\' sull\'Universo Orbe, in conformita\' a quanto e\' detto nel Pater Noster: \'FIAT VOLUNTAS TUA, SICUT IN COELO ET IN TERRA\'.',
  bio8: 'Luisa, come figlia della Chiesa, fu sempre sottomessa ed ubbidiente. Durante il periodo dal 1884 fino alla sua morte nel 1947, fu sottomessa alla cura ed obbedienza di vari confessori inviati dal Vescovo della sua Arcidiocesi. Il suo secondo confessore, Don Gennaro di Gennaro il 28 febbraio del 1899 le diede l\'obbedienza di mettere per iscritto tutto quanto succedeva tra Gesu\' e lei e le grazie che continuamente riceveva. Fu allora che Luisa si decise a vincere la ripugnanza di rendere pubblico quello che viveva nel suo interno. E cosi\', con gran sforzo, scrisse più di 2.000 capitoli, raccolti in trentasei volumi, senza contare centinaia di lettere, \'Le Ore della Passione di Nostro Signore Gesu\' Cristo\', e \'La Vergine Maria nel Regno della Divina Volonta\'.',
  bio9: 'Uno dei suoi confessori e promotore più importante della Divina Volonta\' (la dottrina che Gesu\' insegno\' a Luisa), fu Sant\'Annibale Maria Di Francia che fu Revisore Ecclesiastico dei volumi, (diede il suo Nulla Osta a 19 dei 36 volumi) e primo apostolo del Regno del Fiat Divino (come Gesu\' stesso lo definisce nel volume 20 del suo diario, il 6 novembre 1926). ',
  bio10: 'Luisa mori\' prima di compiere ottantadue anni di eta\'. il 4 marzo di 1947, dopo una breve ma fatale polmonite (l\'unica malattia diagnosticata nella sua vita), entro\' nella vita eterna per continuare a vivere immersa nella Divina Volonta\' nel Cielo, come lo era in terra. Nel 1993, i suoi resti furono trasportati nel Santuario di Santa Maria Greca, grazie al suo ultimo confessore Don Benedetto Calvi. Il 20 novembre 1994 (nella Festa di Cristo Re), la Santa Sede diede il suo \'Nulla Osta\' all\'Arcidiocesi di Trani-Barletta-Bisceglie, guidata da S.E. Mons. Carmelo Cassati, per l\'apertura ufficiale della Causa di Canonizzazione. Il 29 novembre del 2005 S.E. Mons. Giovan Battista Pichierri, chiuse la fase diocesana, raccogliendo una moltitudine di documenti ed attestazioni sulla fama di santita\' della Serva di Dio, iniziando cosi\' la fase romana della causa, dove il Santo Padre la elevera\' agli onori degli altari. ',
  prevAct: 'Gesu\', ti amo!',
  prevAct1: 'Vieni, Divina Volonta\', e prendi possesso',
  prevAct2: 'del mio essere, della mia persona, della mia vita;',
  prevAct3: 'di tutto quello che sono, di tutto quello che ho, di tutto quello che faccio;',
  prevAct4: 'del mio spirito, della mia anima, del mio corpo;',
  prevAct5: 'delle mie facolta\', dei miei sensi, delle mie membra;',
  prevAct6: 'della mia volonta\', della mia intelligenza, della mia memoria;',
  prevAct7: 'dei pensieri della mia mente, dei battiti del mio cuore, dei respiri del mio petto;',
  prevAct8: 'di tutti i pensieri, di tutte le parole, di tutte le opere;',
  prevAct9: 'del mio sguardo, del mio ascolto, della mia voce;',
  prevAct10: 'dei miei movimenti, delle mie azioni, dei miei passi;',
  prevAct11: 'del mio lavoro, della mia stanchezza, del mio riposo;',
  prevAct12: 'dei miei sentimenti, delle mie pene, delle mie gioie;',
  prevAct13: 'della Santa Messa, dei Sacramenti che ricevo, della mia preghiera;',
  prevAct14: 'del mio passato, del mio presente, del mio avvenire;',
  prevAct15: 'della mia vita, della mia morte e della mia eternita\',',
  prevAct16: 'per convertire tutto in lode perfetta ed universale della tua Gloria,',
  prevAct17: 'in Vita della tua Vita, in trionfo del tuo Volere.',
  prevAct18: 'Gesu\', ti amo,',
  prevAct19: 'e la tua Divina Volonta\' sia vita in me',
  prevAct20: 'e mi rivesta di Te.',
  prevAct21: 'Oggi tutto faro\' per Te, con Te ed in Te.',
  prevAct22: 'In ogni istante della mia vita, viva in me',
  prevAct23: 'la tua Vita intera, la tua Morte e la tua Risurrezione.',
  prevAct24: 'Coprimi sotto il manto della tua Vita, del tuo Dolore e del tuo Amore,',
  prevAct25: 'affinche\' io ti adori nella tua Maesta\',',
  prevAct26: 'Ti abbracci nella tua Immensita\',',
  prevAct27: 'Ti possegga nella tua Onnipotenza.',
  prevAct28: 'Ti glorifichi con la tua stessa Gloria,',
  prevAct29: 'Ti lodi con la tua Sapienza,',
  prevAct30: 'Ti benedica con la stessa voce del Padre.',
  prevAct31: 'Ti ringrazi con la tua Giustizia,',
  prevAct32: 'Ti ripari con i tuoi stessi meriti,',
  prevAct33: 'Ti ami col tuo Eterno Amore.',
  prevAct34: 'In ogni istante voglio riempire',
  prevAct35: 'tutta la creazione',
  prevAct36: 'col mio Amore che ti loda e ti ringrazia,',
  prevAct37: 'tutta la tua vita di Redentore',
  prevAct38: 'col mio Amore che ti adora e ti benedice,',
  prevAct39: 'tutta l\'opera della Santificazione',
  prevAct40: 'col mio Amore che ti ama',
  prevAct41: 'e che a nome di tutti ti chiede',
  prevAct42: 'il trionfo del tuo Regno.',
  litanies: 'LITANIE',
  litaniesSub: '(Tratte dai volumi di Luisa)',
  litanies1: 'Padre, nella tua Volonta\'',
  litanies2: 'illuminaci',
  litanies3: 'Figlio, nella tua Volonta\'',
  litanies4: 'trasformaci',
  litanies5: 'Spirito Santo, nella tua Volonta\'',
  litanies6: 'santificaci',
  litanies7: 'Divina Volonta\', faro luminoso del Padre',
  litaniesResponse: 'Venga il tuo Regno',
  litanies8: 'Divina Volonta\', faro redentivo del Figlio',
  litanies9: 'Divina Volonta\', faro santificante dello Spirito Santo',
  litanies10: 'FIAT creante, sostegno della creazione',
  litanies11: 'FIAT redimente, in Gesu\' nostra salvezza',
  litanies12: 'FIAT santificante, che ci modelli nella Santita\' della Trinita\'',
  litanies13: 'FIAT Supremo, che trasformi l\'umano in Divino',
  litanies14: 'FIAT conquistante, che rapisci le umane volonta\'',
  litanies15: 'FIAT Divino, che riallacci la Divinita\' con l\'umanita\'',
  litanies16: 'Divina Volonta\', trasformatrice dei Cuori',
  litanies17: 'Divina Volonta\', depositaria della Divina Volonta\' nelle anime',
  litanies18: 'Divina Volonta\', forza invincibile',
  litanies19: 'Divina Volonta\', Luce dell\'umanita\'',
  litanies20: 'Divina Volonta\', parte operante nella Trinita\'',
  litanies21: 'Divina Volonta\', stella che riflette la Divinita\'',
  litanies22: 'FIAT Divino, ordine della creazione',
  litanies23: 'FIAT regnante nelle anime pacifiche',
  litanies24: 'FIAT redentivo, con la discesa del Verbo',
  litanies25: 'FIAT trionfante, nella Vergine Maria',
  litanies26: 'FIAT parlante, in tutta la creazione',
  litanies27: 'FIAT operante, nel silenzio dei cuori',
  litanies28: 'Divina Volonta\', stella della Divinita\'',
  litanies29: 'Divina Volonta\', modello dell\'Essere Supremo',
  litanies30: 'Divino Volonta\', dispensatrice degli attributi Divini',
  litanies31: 'Divina Volonta\', eco Divino di tutta la creazione',
  litanies32: 'Divina Volonta\', Tabernacolo di Maria SS.',
  litanies33: 'Divina Volonta\', specchio della Santita\' Divina',
  litanies34: 'SS. ma Trinita\', fonte di unita\'',
  litanies35: 'SS. ma Trinita\', essenza di santita\'',
  litanies36: 'SS. ma Trinita\', unione perfetta di volonta\'',
  litanies37: 'Prega per noi Regina del Divin Volere',
  litanies38: 'affinche\' la Divina Volonta\' regni sulla terra come in Cielo.',
  litanies39: 'Pater, Ave e Gloria secondo le intenzioni del Sommo Pontefice.',
  prayer: 'PREGHIERA',
  prayer1: 'O Santissima Trinita\',',
  prayer2: 'il nostro Salvatore Gesu\' Cristo ci ha insegnato di chiedere sempre nelle nostre preghiere che sia sempre glorificato il nome del Padre, che venga il Suo Regno e che si faccia la Sua Volonta\'.',
  prayer3: 'Desiderosi di espandere tale Regno di Amore, di Giustizia e di Pace, umilmente imploriamo la Glorificazione della Serva Luisa, la piccola figlia del Divin Volere, che con le sue preghiere, la sua immolazione nel letto del dolore ed il suo ardente zelo, contribui\' grandemente alla diffusione del Regno di Dio nel mondo ed alla salvezza delle anime.',
  prayer4: 'A suo esempio, supplichiamo Te, Padre, Figlio e Spirito Santo, di aiutarci a portare con gioia le croci che ci riserva la vita, a gloria del tuo Nome e per il bene di tutti.',
  prayerMain1: 'Vieni Volere Supremo a regnare sulla Terra,',
  prayerMain2: 'investi tutte le generazioni,',
  prayerMain3: 'vinci e conquista tutti!'
}
