import {
  GREY, WHITE, TEAL
} from 'styles/colors'

export const styles = {
  container: {
    position: 'fixed',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: `1px solid ${GREY}`,
    backgroundColor: WHITE,
    zIndex: 1100,
    left: 0,
    top: 60,
    height: 60
  },
  subcontainer: {
    width: '100%',
    maxWidth: '961px',
    display: 'flex',
    height: 60,
    justifyContent: 'center',
    alignItems: 'center'
  },
  navlinkActive: {
    width: '25%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60px',
    background: TEAL
  },
  navlink: {
    width: '25%',
    display: 'flex',
    alignItems: 'center',
    height: '60px',
    justifyContent: 'center'
  },
  iconActive: {
    fontSize: 24,
    lineHeight: '24px',
    color: WHITE
  },
  icon: {
    fontSize: 24,
    lineHeight: '24px',
    color: TEAL
  }
}
