export const COMPANY = 'RADOST IT j.d.o.o.'
export const COMPANY_NUMBER = '5620279'
export const VAT_NUMBER = 'HR34157642233'
export const ADDRESS = 'Ulica Grgura Budislavića 99, 23000, Zadar, Croatia'
export const EMAIL = 'info@radostit.com'
export const MAILTO = `mailto:${EMAIL}`
export const LAST_TERMS_UPDATE = 'August 4, 2022'
export const LAST_PRIVACY_UPDATE = 'August 4, 2022'
export const LAST_COOKIE_UPDATE = 'August 4, 2022'
export const ABOUT_COOKIES = 'https://www.aboutcookies.org'
export const ALL_ABOUT_COOKIES = 'https://www.allaboutcookies.org'
export const GOOGLE_PRIVACY = 'http://www.google.com/intl/en/policies/privacy/'
export const GA_OPTOUT = 'http://tools.google.com/dlpage/gaoptout'
export const GOOGLE_COOKIES_WEB_1 = 'https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage'
export const GOOGLE_COOKIES_WEB_2 = 'https://support.google.com/analytics/answer/6004245'
export const YOUTUBE_COOKIES_WEB = 'http://www.google.com/support/youtube/bin/answer.py?hl=en-GB&answer=171780'
export const ICO_WEBSITE = 'https://ico.org.uk/concerns/'
export const GDPR_WEBSITE = 'https://gdpr-info.eu/'
export const NECESSARY_COOKIES = 'Strictly necessary'
export const PERFORMANCE_COOKIES = 'Performance'
export const NECESSARY_COOKIES_NAME = 'ck_necessary'
export const PERFORMANCE_COOKIES_NAME = 'ck_performance'
export const NOT_REMOVABLE_COOKIES = [NECESSARY_COOKIES_NAME, PERFORMANCE_COOKIES_NAME]
export const COOKIE_MESSAGE = 'This website uses browser cookies. Click to agree and allow us to improve and personalize your experience on our website. You can change your mind at anytime.'
export const ACCEPT = 'Accept changes'
export const IGNORE = 'Ignore'
export const COOKIE_POLICY = 'Cookie Policy'
