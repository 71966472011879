/* eslint max-len:0 */
export const en = {
  title: 'Living in the Divine Will',
  biography: 'Biography',
  bio1: 'The Servant of God Luisa Piccarreta was born in the city of Corato, province of Bari, Italy, the morning of the 23th April 1865, In Albis Sunday (currently Feast of the Divine Mercy), and in the same day she was baptised; she always lived there and died in the odor of sanctity the 4th March 1947. She was born from Mrs. Rosa Tarantino and from Mr Vito Nicola Piccarreta, worker of an estate of the Mastrorilli Family. The little Luisa, fourth of five children, was of shy temperament, fearful; despite that, she was also lively and happy.',
  bio2: 'Sunday `in Albis` of 1874, at 9 years old, she received the First Holy Communion and on the same day the Sacrament of Confirmation. As a child, she already showed a strong inclination to dedicate long periods of time to meditation and prayer, having as fundamental elements of the interior life an ardent love for Jesus suffering in his Passion and prisoner of Love in the Eucharist, and a solid and mature devotion for the Most Holy Virgin Mary. Her parents did not give much attention to these inclinations, until a misterious disease started to manifest in their daughter, which forced her to bed. Doctors suggested the visit of a priest, as they could not find the cause and could not provide a diagnosis. They remained astonished when at the sign of the Cross Luisa returned in her `usual state`, as she would have call it years later in her writings.',
  bio3: 'When she was about 18 years old, while she was working in her room , while she was meditating the Passion of Jesus, she felt her heart oppressed and she felt breathless, scared, she went out to the balcony and from there she saw the street full of people that were pushing Jesus, which was bringing the Cross. Then Jesus, suffering and bloody, lifted His eyes towards her while pronouncing these words: `Soul, help me!`.',
  bio4: 'Luisa entered her room with the heart torn and, crying, she said to Him: `How much you suffer, oh my good Jesus! May I help you at least to free you from those angry wolfs, or at least suffer Your sufferings, Your pains and your fatigue, in your place, to give you the biggest relief! Oh, my Good!, do that I may suffer, as it is not fair that you should suffer a lot for my love and I, a sinner, should stay without suffering for You`. And from that moment, always repeating her FIAT, periods passed in the bed became always more frequent until the complete immobility for 62 years.',
  bio5: 'In this `little prison` Jesus made her know the great desire of His Heart, that man may live in His Will, to return in the order, the place and the purpose for which he was created by God. This is what He Himself taught us to ask in the Our Father `Thy Kingdom come, Thy Will be done, on earth as it is in Heaven`. So He deposited in her His marvellous Truths, so that she, as `herald` of the Kingdom, depositary and secretary of the treasures of the Divine Will, could make knowthe eternal decree of the coming of His Kingdom in the Church and in the entire world.',
  bio6: 'St. Annibal Di Francia writes about that:',
  bio7: '`Our Lord, which century after century increases always always more the wonders of His Love, seems to have made of this virgin, that He calls `the most little which I found on earth`, destitute of every education, a suitable instrument for such a sublime Mission, which no other mission can compare to, that is the triumph of the Divine Will over the Orbe Universe, in compliance to what has been said in the Our Father:`THY WILL BE DONE, ON EARTH AS IT IS IN HEAVEN.`',
  bio8: 'Luisa, as daughter of the Church, was always submissive and obedient. During the period from 1884 until her death in 1947, she was submissive to the cure and obedience of several confessors sent by the Bishop of her Archdiocese. Her second confessor, Fr. Gennaro di Gennaro, the 28th of February 1899 gave her the obedience to put in writing all that was happening among Jesus and her and the graces that she consinuously received. Luisa then decided to win the repugnance to make public what she lived in her interior. And so, with a lot of effort, she wrote over 2\'000 chapters, collected in 36 volumes, without counting hundreds of letters, `The Hours of the Passion of Our Lord Jesus Christ`, and `The Virgin Mary in the Kingdom of the Divine Will`.',
  bio9: 'One of her confessors and greater promoter of the Divine Will (the doctrine which Jesus taught to Luisa) was St. Annibal of France, which was Ecclesiastical Reviewer of the volumes (he gave his Nulla Osta to 19 of the 36 volumes) and first apostle of the Kingdom of the Divine Will (as Jesus Himself defines him in volume 20 of her diary, the 6th of November 1926).',
  bio10: 'Luisa died before the age of 82. The 4th of March 1947, after a short but fatal pneumonia (the only disease diagnosed her in her life), she entered the eternal life to continue to live submerged in the Divine Will in Heaven, as she was on earth. In 1993, her remains moved to the Sanctuary of St. Mary Greek, thanks to her last confessor, Fr. Benedetto Calvi. The 20th of November 1994 (during the Feast of Christ the King), the Holy See gave his `Nulla Osta` to the archdiocese of Trani-Barletta-Bisceglie, led by Reverend Monsignor Carmelo Cassati, for the official opening of her cause of Canonisation. The 29th of November 2005 Reverend Monsignor Giovan Battista Pichierri, closed the diocesan phase, collecting a multitude of documents and attestations of the reputation for holiness of the Servant of God, starting the Roman phase of the cause, where the Holy Father will elevate her to the Honors of the Altar.',
  prevAct: 'JESUS I LOVE YOU',
  prevAct1: 'Come, Divine will, and take possession',
  prevAct2: 'of my being, of my person, of my life;',
  prevAct3: 'of all that I am, of all that I have, of all that I do;',
  prevAct4: 'of my spirit, of my soul, of my body;',
  prevAct5: 'of my faculties, of my senses, of my members;',
  prevAct6: 'of my will, of my intelligence, of my memory;',
  prevAct7: 'of the thoughts of my mind, of the beats of my heart, of the breaths of my chest;',
  prevAct8: 'of all the thoughts, of all the words, of all the deeds;',
  prevAct9: 'of my sight, of my listening, of my voice;',
  prevAct10: 'of my movements, of my actions, of my steps;',
  prevAct11: 'of my work, of my tireness, of my rest;',
  prevAct12: 'of my feelings, of my sufferings, of my joys:',
  prevAct13: 'of my prayer, of the Holy Mass, of the Sacraments I receive (and give);',
  prevAct14: 'of my past, of my present, of my future;',
  prevAct15: 'of my life, of my death and of my eternity,',
  prevAct16: 'to convert everything in perfect and universal praise of Your Glory,',
  prevAct17: 'in life of Your Life,in the trimph of Your Will.',
  prevAct18: 'Jesus, I love You,',
  prevAct19: 'and Your Divine Will may be life in me',
  prevAct20: 'and clothe me of You.',
  prevAct21: 'Today I will do everything for You, with You and in You.',
  prevAct22: 'In every instant of my life, may live in me',
  prevAct23: 'Your entire Life, Your Death and Your Resurrection.',
  prevAct24: 'Cover me under the mantle of Your Life, of Your Sorrow and of Your Love,',
  prevAct25: 'so that I may adore You in Your Truth,',
  prevAct26: 'may embrace You in Your Immensity,',
  prevAct27: 'may I possess You in Your Mighty.',
  prevAct28: 'May I glorify You with Your same Glory,',
  prevAct29: 'may I praise You with Your Wisdom,',
  prevAct30: 'may I bless You with the same voice of the Father.',
  prevAct31: 'May I thank You with Your Justice,',
  prevAct32: 'may I repair You with Your same merits,',
  prevAct33: 'may I love You with Your Eternal Love.',
  prevAct34: 'In every instant I want to fill',
  prevAct35: 'all Creation',
  prevAct36: 'with my love which praises and thanks You,',
  prevAct37: 'all Your life of Redeemer',
  prevAct38: 'with my love which adores and blesses You,',
  prevAct39: 'all the work of Santification',
  prevAct40: 'with my Love which loves You',
  prevAct41: 'and on behalf of everybody asks You',
  prevAct42: 'the triumph of Your Kingdom.',
  litanies: 'LITANY',
  litaniesSub: '(From Luisa\'s Volumes)',
  litanies1: 'Father, in Your Will',
  litanies2: 'enlighten us',
  litanies3: 'Son, in Your Will',
  litanies4: 'transform us',
  litanies5: 'Holy Spirit, in Your Will',
  litanies6: 'sanctify us',
  litanies7: 'Divine Will, bright lighthouse of the Father',
  litaniesResponse: 'Thy Kingdom Come',
  litanies8: 'Divine Will, redemptive lighthouse of the Son',
  litanies9: 'Divine Will, sanctifying lighthouse of the Holy Spirit',
  litanies10: 'Creating FIAT, support of the creation',
  litanies11: 'Redemptive FIAT, in Jesus our salvation',
  litanies12: 'Sanctifying FIAT, which models us in the Sanctity of the Trinity',
  litanies13: 'Supreme FIAT, which transforms the human in the Divine',
  litanies14: 'Conquering FIAT, which kidnaps the human wills',
  litanies15: 'Divine FIAT, which reconnects the Divinity with the humanity',
  litanies16: 'Divine Will, transformer of the hearts',
  litanies17: 'Divine Will, depositary of the Divine Will in the souls',
  litanies18: 'Divine Will, invincible strength',
  litanies19: 'Divine Will, Light of humanity',
  litanies20: 'Divine Will, working part in the Trinity',
  litanies21: 'Divine Will, star which reflects the Divinity',
  litanies22: 'Divine FIAT, order of creation',
  litanies23: 'FIAT ruling in the peaceful souls',
  litanies24: 'Redemptive FIAT, with the descent of the Word',
  litanies25: 'Triumphant FIAT, in the Virgin Mary',
  litanies26: 'Talking FIAT, in all the creation',
  litanies27: 'Working FIAT, in the silence of the hearts',
  litanies28: 'Divine Will, star of the Divinity',
  litanies29: 'Divine Will, model of the Supreme Being',
  litanies30: 'Divine Will, dispenser of the Divine attributes',
  litanies31: 'Divine Will, Divine echo of all the creation',
  litanies32: 'Divine Will, Tabernacle of Mary Most Holy',
  litanies33: 'Divine Will, mirror of the Divine Sanctity',
  litanies34: 'Most Holy Trinity, fount of unity',
  litanies35: 'Most Holy Trinity, essence of sanctity',
  litanies36: 'Most Holy Trinity, perfect union of Will',
  litanies37: 'Pray for us, Queen of the Divine Will',
  litanies38: 'that the Divine Will may reign on earth as in Heaven.',
  litanies39: 'Pater, Ave, Gloria for the Pope\'s intentions.',
  prayer: 'PRAYER',
  prayer1: 'O Most Holy Trinity,',
  prayer2: 'our Savior, Jesus Christ taught us to always ask in our prayers that the name of the Father be always glorified, that His Kingdom come and His Will be done.',
  prayer3: 'Eager to expand that Kingdom of Love, Justice and Peace, we humbly implore the glorification of the Servant Luisa, the little daughter of the Divine Will, that with her prayers, her immolation in the bed of pain, and her ardent zeal, greatly contributed to spreading the Kingdom of God in the world and to the salvation of souls.',
  prayer4: 'In her example, we beg You, Father, Son and Holy Spirit, to help us to bring with joy the crosses that life holds for us, to the glory of Your Name and for the good of all.',
  prayerMain1: 'Come Supreme Will to reign on Earth,',
  prayerMain2: 'invest all generations,',
  prayerMain3: 'win and conquer all!'
}
