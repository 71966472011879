import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
// import {
//   isLSEmpty, readCookie, upsertCookie, removeAllCookies
// } from 'utils/cookies'
import {
  isLSEmpty, readLS, upsertLS, removeAllLSs
} from 'utils/localStorage'
import ReactGA from 'react-ga'
import { GA_CODE } from 'components/constants'
import { singleAction } from 'controller/actions'
import { UPDATE_COOKIE_VALUE, SHOW_COOKIE_BAR, TOGGLE_COOKIE } from 'controller/constants'
import { NOT_REMOVABLE_COOKIES, PERFORMANCE_COOKIES_NAME } from 'common/legal/constants'
import CookieBar from './CookieBar'

class CookieBarContainer extends Component {
  componentDidMount() {
    const { singleActionProp } = this.props

    if (!isLSEmpty(PERFORMANCE_COOKIES_NAME)) {
      const performanceCookieValue = readLS(PERFORMANCE_COOKIES_NAME) === 'true'

      singleActionProp({
        type: SHOW_COOKIE_BAR,
        payload: { value: false }
      })

      singleActionProp({
        type: TOGGLE_COOKIE,
        payload: { value: performanceCookieValue }
      })

      singleActionProp({
        type: UPDATE_COOKIE_VALUE,
        payload: { value: performanceCookieValue }
      })

      if (performanceCookieValue) {
        this.trackPage()
      }
    }
  }

  componentDidUpdate = prevProps => {
    const { location, cookieStatus } = this.props

    if (location !== prevProps.location || cookieStatus !== prevProps.cookieStatus) {
      if (cookieStatus.approved) {
        this.trackPage()
      }
    }
  }

  trackPage = () => {
    const { location } = this.props

    ReactGA.pageview(location.pathname)
  }

  toggleCookie = () => {
    const { singleActionProp, cookieStatus } = this.props

    singleActionProp({
      type: TOGGLE_COOKIE,
      payload: { value: !cookieStatus.uiApproved }
    })
  };

  approveCookie = () => {
    const { singleActionProp, cookieStatus } = this.props
    const value = cookieStatus.uiApproved

    if (!value) {
      upsertLS(PERFORMANCE_COOKIES_NAME, value)
      removeAllLSs(NOT_REMOVABLE_COOKIES)
    } else {
      upsertLS(PERFORMANCE_COOKIES_NAME, value)
      ReactGA.initialize(GA_CODE)
    }

    singleActionProp({
      type: UPDATE_COOKIE_VALUE,
      payload: { value }
    })


    this.rejectCookie()
  };

  rejectCookie = () => {
    const { singleActionProp } = this.props
    singleActionProp({
      type: SHOW_COOKIE_BAR,
      payload: { value: false }
    })
  };

  showCookieBar = () => {
    const { singleActionProp } = this.props
    singleActionProp({
      type: SHOW_COOKIE_BAR,
      payload: { value: true }
    })
  };

  render() {
    const { cookieStatus } = this.props
    return (
      <CookieBar
        cookieStatus={cookieStatus}
        toggleCookie={this.toggleCookie}
        approveCookie={this.approveCookie}
        rejectCookie={this.rejectCookie}
        showCookieBar={this.showCookieBar}
      />
    )
  }
}

CookieBarContainer.propTypes = {
  cookieStatus: PropTypes.shape({
    uiApproved: PropTypes.bool,
    approved: PropTypes.bool
  }).isRequired,
  singleActionProp: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
}

CookieBarContainer.defaultProps = {
  location: {
    pathname: '/'
  }
}

const mapDispatchToProps = dispatch => {
  return {
    singleActionProp: props => dispatch(singleAction(props))
  }
}

const mapStateToProps = state => ({
  cookieStatus: state.cookies.cookieStatus
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(CookieBarContainer))
