import React from 'react'
import ReactDOM from 'react-dom'
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from 'controller/store'
import TermsConditions from 'scenes/TermsConditions'
import PrivacyPolicy from 'scenes/PrivacyPolicy'
import CookiePolicy from 'scenes/CookiePolicy'
import Homepage from 'scenes/Homepage'
import PrevenientAct from 'scenes/PrevenientAct'
import Litanies from 'scenes/Litanies'
import Prayer from 'scenes/Prayer'
import Header from 'components/Header'
import Navbar from 'components/Navbar'
import Footer from 'components/Footer'
import { CookieBar } from 'common/legal'
import {
  LINK_HOMEPAGE, LINK_PRIVACY, LINK_TERMS, LINK_COOKIE,
  LINK_PREVENIENT_ACT, LINK_LITANIES, LINK_PRAYER
} from 'controller/constants'
import 'semantic-ui-css/semantic.min.css'
import { styles } from './styles'
import './index.css'
import './i18n'

// to view the state tree in the console
window.store = store

const provider = (
  <Provider store={store}>
    <BrowserRouter>
      <div style={styles.container}>
        <Header />
        <Navbar />
        <Switch>
          <Route exact path={LINK_HOMEPAGE} component={Homepage} />
          <Route exact path={LINK_PREVENIENT_ACT} component={PrevenientAct} />
          <Route exact path={LINK_LITANIES} component={Litanies} />
          <Route exact path={LINK_PRAYER} component={Prayer} />
          <Route exact path={LINK_PRIVACY} component={PrivacyPolicy} />
          <Route exact path={LINK_TERMS} component={TermsConditions} />
          <Route exact path={LINK_COOKIE} component={CookiePolicy} />
        </Switch>
        <Footer />
        <CookieBar />
      </div>
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(provider, document.getElementById('root'))
