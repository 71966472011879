import { TEAL } from 'styles/colors'

export const styles = {
  container: {
    width: '100%'
  },
  internalContainer: {
    width: '100%',
    maxWidth: '961px',
    margin: 'auto',
    padding: 30,
  },
  title: {
    fontSize: 33,
    fontWeight: 700,
    textAlign: 'center',
    color: TEAL,
    display: 'block'
  },
  paragraph: {
    fontSize: 14,
    textAlign: 'center'
  }
}
