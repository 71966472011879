import {
  GREY, WHITE, TEAL
} from 'styles/colors'

export const styles = {
  header: {
    position: 'fixed',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: `1px solid ${GREY}`,
    backgroundColor: WHITE,
    zIndex: 1100,
    left: 0,
    top: 0,
    height: 60
  },
  headerContainer: {
    width: '100%',
    maxWidth: '961px',
    display: 'flex',
    height: 60,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  logo: {
    fontSize: 40,
    marginLeft: 10,
    color: WHITE,
    fontWeight: 700
  },
  navbar: {
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: WHITE
  },
  flagButtonActive: {
    background: WHITE,
    width: 40,
    height: 40,
    borderRadius: 20,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `2px solid ${TEAL}`
  },
  flagButton: {
    background: WHITE,
    width: 40,
    height: 40,
    padding: 0,
    borderRadius: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `2px solid ${GREY}`
  },
  flag: {
    margin: 0,
    padding: 0
  }
}
