import { TEAL } from 'styles/colors'

export const styles = {
  container: {
    width: '100%'
  },
  internalContainer: {
    width: '100%',
    maxWidth: '961px',
    margin: 'auto',
    padding: 30,
  },
  title: {
    fontSize: 33,
    fontWeight: 700,
    textAlign: 'center',
    color: TEAL,
    display: 'block'
  },
  paragraph: {
    fontSize: 14
  },
  videoContainer: {
    position: 'relative',
    paddingBottom: '56.25%',
    paddingTop: 25,
    height: 0,
    marginBottom: 50
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%'
  }
}
