import React from 'react'
import { useTranslation } from 'react-i18next'
import Fade from 'react-reveal/Fade'
import uuidv4 from 'uuid/v4'
import { styles } from './styles'

const Litanies = () => {
  const { t } = useTranslation()

  const getTopLitanies = () => {
    const paragraph = []
    for (let i = 1; i < 6; i += 2) {
      paragraph.push(
        <Fade delay={300} key={`paragraph${uuidv4()}`}>
          <p style={styles.paragraph}>{t(`litanies${i}`)}</p>
          <p style={styles.response}>{t(`litanies${i + 1}`)}</p>
        </Fade>
      )
    }

    return paragraph
  }

  const getCentralLitanies = () => {
    const paragraph = []
    for (let i = 7; i < 37; i++) {
      paragraph.push(
        <Fade delay={300} key={`paragraph${uuidv4()}`}>
          <p style={styles.paragraph}>{t(`litanies${i}`)}</p>
          <p style={styles.response}>{t('litaniesResponse')}</p>
        </Fade>
      )
    }

    return paragraph
  }

  return (
    <div style={styles.container}>
      <div style={styles.internalContainer}>
        <Fade delay={300}>
          <p style={styles.title}>{t('litanies')}</p>
        </Fade>
        <Fade delay={300}>
          <p style={styles.subtitle}>{t('litaniesSub')}</p>
        </Fade>
        {getTopLitanies()}
        {getCentralLitanies()}
        <Fade delay={300}>
          <p style={styles.paragraph}>{t('litanies37')}</p>
          <p style={styles.response}>{t('litanies38')}</p>
        </Fade>
        <Fade delay={300}>
          <p style={styles.paragraph}>{t('litanies39')}</p>
        </Fade>
      </div>
    </div>
  )
}

export default Litanies
