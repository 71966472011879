import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Navbar from './Navbar'

const NavbarContainer = ({ location }) => <Navbar activePath={location.pathname} />

NavbarContainer.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
}

export default withRouter(NavbarContainer)
