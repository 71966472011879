import React, { Component } from 'react'
import i18n from 'i18next'
import { singleAction } from 'controller/actions'
import { connect } from 'react-redux'
import { UPDATE_LANGUAGE } from 'controller/constants'
import PropTypes from 'prop-types'
import Header from './Header'

class HeaderContainer extends Component {
  switchToEn = () => {
    const { singleActionProp } = this.props

    singleActionProp({
      type: UPDATE_LANGUAGE,
      payload: { value: 'en' }
    })

    return i18n.changeLanguage('en')
  }

  switchToIt = () => {
    const { singleActionProp } = this.props

    singleActionProp({
      type: UPDATE_LANGUAGE,
      payload: { value: 'it' }
    })

    return i18n.changeLanguage('it')
  }

  render() {
    const { lang } = this.props

    return (
      <Header
        currentLanguage={lang}
        switchToEn={this.switchToEn}
        switchToIt={this.switchToIt}
      />
    )
  }
}


HeaderContainer.propTypes = {
  lang: PropTypes.string,
  singleActionProp: PropTypes.func.isRequired,
}

HeaderContainer.defaultProps = {
  lang: 'en'
}

const mapDispatchToProps = dispatch => {
  return {
    singleActionProp: props => dispatch(singleAction(props))
  }
}

const mapStateToProps = state => ({
  lang: state.language.active
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderContainer)
