import React from 'react'
import { useTranslation } from 'react-i18next'
import Fade from 'react-reveal/Fade'
import uuidv4 from 'uuid/v4'
import { styles } from './styles'

const Prayer = () => {
  const { t } = useTranslation()

  const getPrayer = () => {
    const paragraph = []
    for (let i = 1; i < 5; i++) {
      paragraph.push(
        <Fade delay={300} key={`paragraph${uuidv4()}`}>
          <p style={styles.paragraph}>{t(`prayer${i}`)}</p>
        </Fade>
      )
    }

    return paragraph
  }

  const getInvocation = () => {
    const paragraph = []
    for (let i = 1; i < 4; i++) {
      paragraph.push(
        <Fade delay={300} key={`paragraph${uuidv4()}`}>
          <p style={styles.paragraphInvocation}>{t(`prayerMain${i}`)}</p>
        </Fade>
      )
    }

    return paragraph
  }

  return (
    <div style={styles.container}>
      <div style={styles.internalContainer}>
        <Fade delay={300}>
          <p style={styles.title}>{t('prayer')}</p>
        </Fade>
        {getPrayer()}
        {getInvocation()}
      </div>
    </div>
  )
}

export default Prayer
