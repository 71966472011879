import { combineReducers } from 'redux'
import { cookies } from './cookies'
import { language } from './language'

const reducers = combineReducers({
  cookies,
  language
})

export default reducers
