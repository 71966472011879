// Routes
export const LINK_HOMEPAGE = '/'
export const LINK_PRIVACY = '/privacy-policy'
export const LINK_TERMS = '/terms-conditions'
export const LINK_CONTACTS = '/contact-us'
export const LINK_COOKIE = '/cookie-policy'
export const LINK_PREVENIENT_ACT = '/prevenient-act'
export const LINK_LITANIES = '/litanies'
export const LINK_PRAYER = '/prayer'
export const UPDATE_COOKIE_VALUE = 'UPDATE_COOKIE_VALUE'
export const SHOW_COOKIE_BAR = 'SHOW_COOKIE_BAR'
export const TOGGLE_COOKIE = 'TOGGLE_COOKIE'
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE'
