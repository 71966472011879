import { UPDATE_COOKIE_VALUE, SHOW_COOKIE_BAR, TOGGLE_COOKIE } from '../constants'

const initState = {
  cookieStatus: {
    approved: false,
    visible: true,
    uiApproved: true
  }
}

export const cookies = (state = initState, action) => {
  const { type, payload } = action
  switch (type) {
    case UPDATE_COOKIE_VALUE:
      return {
        ...state,
        cookieStatus: {
          ...state.cookieStatus,
          approved: payload.value
        }
      }
    case SHOW_COOKIE_BAR:
      return {
        ...state,
        cookieStatus: {
          ...state.cookieStatus,
          visible: payload.value
        }
      }
    case TOGGLE_COOKIE:
      return {
        ...state,
        cookieStatus: {
          ...state.cookieStatus,
          uiApproved: payload.value
        }
      }
    default: return state
  }
}
